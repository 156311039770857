<template>
    <div>
        <template v-if="additionalInfoLoaded">
            <div class="row">
                <div class="col-12">
                    <InputFieldError attribute="attributes" :errors="errors"/>
                </div>

                <div class="col-md-6 my-3">
                    <label for="quantity-requested">
                        {{ $store.state.translations.translations['Quantity requested'] }} *
                    </label>

                    <input id="quantity-requested"
                           type="number"
                           min="1"
                           :max="maxRequested"
                           class="form-control"
                           v-model="quantityRequested"
                    >

                    <InputFieldError attribute="RTN_PDT_QUANTITY_REQUESTED" :errors="errors"/>
                </div>

                <div class="col-md-6 my-3" v-if="params.purchaseDateDisplay">
                    <label for="purchase-date">
                        {{ $store.state.translations.attributeLabels['RTN_PDT_PURCHASE_DATE'] }} *
                    </label>

                    <Datepicker v-model="purchaseDate"
                                :format="datepickerFormatter"
                                :enableTimePicker="false"
                                :autoApply="true"
                                :placeholder="$store.state.translations.placeHolders['RTN_PDT_PURCHASE_DATE'] ? $store.state.translations.placeHolders['RTN_PDT_PURCHASE_DATE'] : $store.state.translations.translations['Please select your purchase date.']"
                    ></Datepicker>

                    <InputFieldError attribute="RTN_PDT_PURCHASE_DATE" :errors="errors"/>
                </div>

                <div class="col-md-6 my-3" v-if="params.warrantyDateDisplay">
                    <label for="warranty-date">
                        {{ $store.state.translations.attributeLabels['RTN_PDT_WARRANTY_DATE'] }} *
                    </label>

                    <Datepicker v-model="warrantyDate"
                                :format="datepickerFormatter"
                                :enableTimePicker="false"
                                :autoApply="true"
                                :placeholder="$store.state.translations.placeHolders['RTN_PDT_WARRANTY_DATE'] ? $store.state.translations.placeHolders['RTN_PDT_WARRANTY_DATE'] : $store.state.translations.translations['Please select your warranty date.']"
                    ></Datepicker>

                    <InputFieldError attribute="RTN_PDT_WARRANTY_DATE" :errors="errors"/>
                </div>

                <div class="col-md-6 my-3" v-if="params.returnReasonDisplay === 'product'">
                    <label>
                        {{ $store.state.translations.attributeLabels['RTN_PDT_RETURN_REASON'] }} *
                    </label>

                    <v-select :options="values(returnReasons)"
                              :clearable="false"
                              :filterable="true"
                              :reduce="returnReason => returnReason.name"
                              :searchable="true"
                              v-model="selectedReturnReason"
                              :placeholder="$store.state.translations.placeHolders['RTN_PDT_RETURN_REASON'] ?  $store.state.translations.placeHolders['RTN_PDT_RETURN_REASON'] : $store.state.translations.translations['Please select your return reason.']">
                    </v-select>
                </div>

                <div class="col-md-6 my-3"
                     v-if="params.serviceRequestDisplay === 'product' && values(serviceRequests).length">
                    <label>
                        {{ $store.state.translations.attributeLabels['RTN_PDT_SERVICE_REQUEST'] }} *
                    </label>

                    <v-select :options="values(serviceRequests)"
                              :clearable="false"
                              :filterable="true"
                              :reduce="serviceRequest => serviceRequest.name"
                              :searchable="true"
                              v-model="selectedServiceRequest"
                              :placeholder="$store.state.translations.placeHolders['RTN_PDT_SERVICE_REQUEST'] ?  $store.state.translations.placeHolders['RTN_PDT_SERVICE_REQUEST'] : $store.state.translations.translations['Please select your reimbursement request.']">
                    </v-select>
                </div>
            </div>

            <CustomAttributesForm :customAttributes="customAttributes" :form="form" :errors="errors"
                                  ref="customAttrForm"/>
        </template>
    </div>
</template>

<script>
    import CustomAttributesForm from './_CustomAttributesForm.vue';
    import InputFieldError from './_InputFieldError.vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import vSelect from 'vue-select';
    import _ from 'lodash';

    export default {
        components: {
            'CustomAttributesForm': CustomAttributesForm,
            'InputFieldError': InputFieldError,
            'Datepicker': Datepicker,
            'v-select': vSelect,
        },
        props: [
            'params',
            'articleGroupId',
            'productId',
            'getDetailsOnLoad',
            'errors',
            'maxRequested'
        ],
        data() {
            return {
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                additionalInfoLoaded: false,
                customAttributes: null,
                form: {},
                quantityRequested: 1,
                purchaseDate: null,
                warrantyDate: null,
                returnReasons: {},
                serviceRequests: {},
                selectedReturnReason: null,
                selectedServiceRequest: null,
            }
        },
        methods: {
            getData() {
                const customAttributesData = this.$refs.customAttrForm.getData();

                let formData = {
                    'RTN_PDT_QUANTITY_REQUESTED': this.quantityRequested,
                };

                if (this.params.warrantyDateDisplay) {
                    formData['RTN_PDT_WARRANTY_DATE'] = this.warrantyDate;
                }

                if (this.params.purchaseDateDisplay) {
                    formData['RTN_PDT_PURCHASE_DATE'] = this.purchaseDate;
                }

                if (this.params.returnReasonDisplay === 'product') {
                    formData['RTN_PDT_RETURN_REASON'] = this.selectedReturnReason;
                }

                if (this.params.serviceRequestDisplay === 'product') {
                    formData['RTN_PDT_SERVICE_REQUEST'] = this.selectedServiceRequest;
                }

                return {
                    'attributes': formData,
                    'customAttributes': customAttributesData
                };
            },
            getAdditionalInfo(productId = null) {
                this.additionalInfoLoaded = false;

                this.$store.dispatch('products/getAdditionalInfo', {
                    productId: productId,
                    merchantSetupId: this.merchantSetupId,
                    articleGroupId: this.articleGroupId
                }).then((data) => {
                    this.$emit('productDetailsFormLoaded', true);
                    this.additionalInfoLoaded = true;
                    this.customAttributes = data.customAttributes;

                    if (data.returnReasons) {
                        this.returnReasons = _.toPairs(data.returnReasons).map(a => {
                            return {name: a[0], label: a[1]};
                        });

                        if (this.returnReasons.length === 1) {
                            this.selectedReturnReason = this.returnReasons[0].name;
                        }
                    }

                    if (data.serviceRequests) {
                        this.serviceRequests = _.toPairs(data.serviceRequests).map(a => {
                            return {name: a[0], label: a[1]};
                        });

                        if (this.serviceRequests.length === 1) {
                            this.selectedServiceRequest = this.serviceRequests[0].name;
                        }
                    }

                    if (productId) {
                        this.quantityRequested = data.quantityRequested;
                        this.purchaseDate = data.purchaseDate;
                        this.warrantyDate = data.warrantyDate;
                        this.selectedReturnReason = data.returnReason;
                        this.selectedServiceRequest = data.serviceRequest;
                    }

                    this.$nextTick(() => {
                        this.$refs.customAttrForm?.setCustomAttributeValues();
                    });
                });
            },
            datepickerFormatter(date) {
                let d = new Date(date);
                return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
            },
            values(input) {
                return _.values(input);
            }
        },
        created() {
            this.quantityRequested = this.maxRequested ? this.maxRequested : 1;
            if (this.getDetailsOnLoad) {
                this.getAdditionalInfo(this.productId);
            } else {
                // this.$emit('productDetailsFormLoaded', true);
            }
        },
        watch: {
            purchaseDate(timestring) {
                this.purchaseDate = this.datepickerFormatter(timestring);
            },
            warrantyDate(timestring) {
                this.warrantyDate = this.datepickerFormatter(timestring);
            },
            quantityRequested() {
                if(!this.maxRequested)
                    return;

                if (this.quantityRequested > this.maxRequested)
                    this.quantityRequested = this.maxRequested;
            }
        }
    }
</script>

<style>

</style>
